@import (reference) '../../../styles/_library.less';

.f-kamino-impulse {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9997;
  border-top: 1px solid #d8d8d8;
  cursor: pointer;

  .f-kamino-wrapper {
    position: relative;
    display: grid;
    width: 100%;
    height: 120px;
    background: #fff;
    grid-template-columns: 1fr 320px;
  }

  &[data-format='impulse-display'] .f-kamino-wrapper {
    grid-template-columns: 1fr;
  }

  &[data-format='impulse-product'] .f-kamino-wrapper {
    grid-template-columns: 1fr 360px;
    .f-nCarousel__item {
      min-width: 360px;
      .f-kamino-product {
        overflow: visible;
      }
    }
  }

  .f-kamino-image {
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .f-kamino-video {
    height: 120px;
    margin: auto;

    &-wrapper {
      display: flex;
    }
  }

  .js-kamino-expand {
    position: absolute;
    top: -30px;
    right: 0px;
    width: 30px;
    height: 30px;
    padding: 0;
    background: rgba(50, 50, 50, 0.8);
    border: none;
    color: white;
  }

  .f-kamino-sponsored {
    position: absolute;
    font-size: 12px;
    line-height: 12px;
    left: 0;
    top: -17px;
    appearance: none;
    color: @color-gray-light;
    border: none;
    border-top: 1px solid @color-silver-darker;
    border-right: 1px solid @color-silver-darker;
    background: white;
  }
}

.f-kamino-impulse,
.f-kamino-impulse-popin {
  .miniFA__button.ff-button {
    min-width: 30px;
    width: 30px;
    height: 30px;
    margin: 0;
    min-height: 30px;
  }
  .ff-button-icon {
    margin: 0;
  }
  .f-kamino-title {
    line-height: 1.2em;
    font-size: 15px;
    p {
      -webkit-line-clamp: 1;
      max-height: 1em;
    }
  }

  .f-kamino-buybox {
    width: 70%;
  }

  .f-kamino-buybox-price {
    display: flex;
    justify-content: space-between;
    position: relative;

    .price {
      font-weight: 700;
      font-size: 19px;
      line-height: 19px;
      color: @color-red;
    }
    &__price-wrapper {
      display: flex;
      flex-direction: column;
    }
    &__bottom-wrapper {
      position: relative;
    }
    .thumbnail-greenLabel {
      right: 38px;
      top: 0;
      left: unset;
    }
  }
}

.f-kamino-impulse {
  .f-kamino-products {
    // width: 30%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .f-nCarousel {
    margin: 0;
    .nCarouselRoundedArrows(30px);
    .nCarouselPositionArrows(5px);

    &__item {
      min-width: 320px;
      padding: 10px 40px;
      height: 120px;
    }
  }

  .f-kamino-visual {
    width: 30%;
    position: relative;
    margin: 0 auto;

    img {
      width: 100%;
      height: auto;
    }
  }
  .js-ProductBuy,
  .js-kamino-image {
    display: contents;
  }

  &[data-format='impulse'] .js-kamino-image {
    display: block;
  }

  .f-kamino-product {
    display: flex;
    min-width: 100%;
    overflow: hidden;
  }
}

//Impulse Popin
.mfp-container .mfp-content .f-kamino-impulse-popin {
  display: flex;
  width: 80%;
  padding: 0;

  .f-kamino-video-wrapper {
    width: 70%;
  }

  .f-kamino-products {
    transition: transform 0.3s ease-in-out;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    width: 100%;
  }

  .f-kamino-visual {
    min-width: 30%;
    position: relative;
    margin: 0 auto;

    img {
      width: auto;
      height: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
    }
  }
  .js-ProductBuy {
    display: contents;
  }

  .f-kamino-product {
    display: flex;
    height: 140px;
    padding: 1em 2.5em 1em 1em;
  }

  .f-kamino-carousel-pagination {
    position: absolute;
    justify-content: center;
    margin: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 1em 0;
    right: 0.5em;
    top: 0;
    li {
      list-style: none;
      line-height: 1;
      margin: 0;
      button {
        padding: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 0;
        opacity: 0.7;
        background: #e9ebf0;
        margin: 3px;

        &.is-active {
          background: #535353;
        }
      }
    }
  }

  .f-kamino-sponsored {
    position: absolute;
    font-size: 12px;
    line-height: 12px;
    color: @color-gray-light;
    border-width: 0;
  }

  .f-kamino-carousel {
    position: relative;
    flex: 1;
    overflow: hidden;
  }

  &[data-carousel-direction='horizontal'] {
    flex-direction: column;

    .f-kamino-products {
      position: relative;
      width: 100%;
      left: 0;
      display: flex;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .f-kamino-product {
      width: 100%;
      flex-shrink: 0;
      margin-bottom: 10px;
    }

    .f-kamino-video-wrapper {
      width: 100%;
    }

    .f-kamino-carousel-pagination {
      flex-direction: row;
      left: 0;
      right: 0;
      bottom: 0;
      top: auto;
      padding: 5px;
    }

    .f-kamino-sponsored {
      right: 6px;
      bottom: 6px;
      background: none;
      border: none;
    }
  }

  &[data-carousel-direction='vertical'] {
    .f-kamino-sponsored {
      padding: 4px 6px;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 12px;
      right: 0px;
      bottom: -28px;
      padding-right: 2px;
    }
  }
}

.f-kamino-impulse-popin {
  display: none;
}
